// SPDX-FileCopyrightText: 2024 Comcast
//
// SPDX-License-Identifier: LicenseRef-Comcast

import { Environment } from '@cxt-cee-chat/merc-ng-core';
import { environment as defaultEnvironment } from './environment.default';

export { initialState } from 'projects/entities/src/lib/domain/state';

export const environment = {
  ...defaultEnvironment,
  env: Environment.Prod,
  production: true
};